.note {
  &__aside {
    background-color: $color-gray-white;
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 15px;
    padding-left: 15px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    &-opacity {
      background-color: #06152b80;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 9;
    }

    .ui {
      &__form-scroll-container {
        height: 70vh;
      }
    }
  }

  &__close {
    background-color: transparent;
    border: none;
    color: $color-gray-label;
    line-height: 16px;
    padding: 0;

    &:focus,
    &:hover {
      background-color: transparent !important;
      border: none;
      box-shadow: none !important;
      color: $color-gray-dark !important;
      outline: none;
    }
  }

  &__data {
    @include fonts($color-gray-label, $font-semi-bold, 12, 14);

    b {
      color: $color-blue-dark;
      font-family: $font-bold;
    }
  }

  &__description {
    @include fonts($color-blue-td, $font-regular, 13, 16);
  }

  &__title {
    border-bottom: 1px solid $color-gray-light;
    padding-bottom: 17px;
    @include fonts($color-blue-gray, $font-semi-bold, 16, 22);
  }
}
