.time-legend {
  &__title {
    @include fonts($color-gray-sep, $font-regular, 12, 13);
  }

  &__item {
    border-radius: 5px;
    display: inline-block;
    @include fonts($color-gray-dark, $font-regular, 11, 11);

    &::before {
      content: '';
      background-color: black;
      border-radius: 50%;
      display: inline-block;
      height: 8px;
      margin: auto 8px auto 0;
      position: relative;
      width: 8px;
    }

    &--first {
      background-color: $color-green;

      &::before {
        background-color: $color-green-dark;
      }
    }

    &--second {
      background-color: $color-yellow;

      &::before {
        background-color: $color-yellow-dark;
      }
    }

    &--third {
      background-color: $color-red-thin;

      &::before {
        background-color: $color-red;
      }
    }
  }
}
