/* stylelint-disable selector-class-pattern */
.satisfaction {
  .script__description {
    @include fonts($color-blue-td, $font-bold, 14, 16);
  }

  .ui__table-dnc,
  .ui__table-sold,
  .ui__table-blocking,
  .ui__table-clickable {
    background-color: inherit !important;
  }

  &__content {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0.75rem;
  }

  &__title {
    @include fonts($color-gray-sep, $font-regular, 14, 18);
  }

  &__modal {
    &-body {
      background-color: $color-gray-white;
    }

    .modal-content {
      width: 670px;
    }

    &-rating {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 150px;
      justify-content: center;
      opacity: 0.5;

      &:hover {
        cursor: pointer;
        opacity: 1;

        .satisfaction__modal-rating-icon {
          width: 84px;
        }
      }

      /* stylelint-disable-next-line no-descending-specificity */
      &-icon {
        width: 76px;
        height: 90px;
      }

      &-text {
        margin-top: 5px;
        @include fonts($color-blue-td, $font-semi-bold, 14, 22);
      }

      &--selected {
        opacity: 1;
      }
    }
  }

  &__score {
    border-radius: 5px;
    display: inline-block;
    margin: auto 5px;
    padding: 4px 10px 0;
    @include fonts($color-gray-dark, $font-regular, 13, 18);

    &-icon-container {
      height: 150px;
    }

    &-icon {
      margin-bottom: 4px;
      width: 18px;
    }

    &--positive {
      background-color: $color-green;
    }

    &--neutral {
      background-color: $color-yellow;
    }

    &--negative {
      background-color: $color-red-thin;
    }
  }

  &__cell {
    font-size: 14px;
  }

  &__widget {
    background-color: $color-gray-white;
    width: 100% !important;

    &-title {
      @include fonts($color-blue-td, $font-bold, 14, 16);
    }

    &-label {
      cursor: pointer;
      @include fonts($color-blue-td, $font-semi-bold, 10, 10);
    }

    &-icon {
      width: 15px;
      margin-right: 5px;
    }

    &-rating {
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      height: 20px;
      justify-content: center;
      margin-left: 10px;
      width: auto;

      &--satisfied {
        background-color: $color-green;
      }

      &--neutral {
        background-color: $color-yellow;
      }

      &--not-satisfied {
        background-color: $color-red-thin;
      }
    }
  }
}
