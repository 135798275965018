.header {
  background-color: $color-white;
  border-bottom: 1px solid $color-gray-light;
  position: fixed;
  width: 100%;
  z-index: 2;

  &__button {
    border-radius: rem(5);
    border-style: solid;
    border-width: 1px;
    display: block;
    font-family: $font-semi-bold;
    font-size: rem(14);
    padding: 11.5px 40px !important;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.25s linear;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &--admin {
      border: none;
      border-radius: 0;
      color: $color-blue-gray;
      padding: 11.5px 15px !important;
      position: relative;

      &::before {
        border: 1px solid $color-blue-light;
        bottom: -17px;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        opacity: 0;
        transition: all 0.15s linear;
        width: 0;
      }

      &:hover,
      &:focus {
        color: $color-blue-med;
      }

      &.active {
        color: $color-blue-dark;
        position: relative;

        &::before {
          opacity: 1;
          width: 100%;
        }
      }
    }

    &--connect {
      background-color: $color-blue-light;
      border-color: $color-blue-light;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-blue-dark;
        border-color: $color-blue-dark;
        color: $color-white;
      }
    }

    &--connected {
      background-color: $color-blue-thin;
      border-color: $color-blue-thin;
      color: $color-blue-dark;

      &:hover,
      &:focus {
        background-color: $color-blue-med;
        border-color: $color-blue-med;
        color: $color-white;
      }
    }

    &--border {
      border-radius: rem(20);
    }

    &--default {
      background-color: $color-blue-dark;
      border-color: $color-blue-dark;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-blue-gray;
        border-color: $color-blue-gray;
        color: $color-white;
      }
    }

    &--disabled {
      background-color: $color-gray-light;
      border-color: $color-gray-light;
      color: $color-blue-dark;

      &:hover,
      &:focus {
        background-color: $color-gray-light;
        border-color: $color-gray-light;
        color: $color-blue-dark;
      }
    }

    &--disconnect {
      background-color: $color-red-light;
      border-color: $color-red-light;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-red-light;
        border-color: $color-red-light;
        color: $color-white;
      }
    }

    &--icon {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--next-priority {
      background-color: $color-green-light;
      border-color: $color-green-light;
      color: $color-blue-gray;

      &:hover,
      &:focus {
        background-color: $color-blue-gray;
        border-color: $color-blue-gray;
        color: $color-green-light;
      }
    }

    &--subitem {
      border: none;
      border-radius: 0;
      color: $color-blue-gray;
      font-size: rem(12);
      padding: 10px 15px !important;
      position: relative;
      transition: all 0.15s linear;

      &:hover,
      &:focus {
        background-color: $color-gray-opacity;
        color: $color-blue-dark;
      }

      &.active {
        color: $color-blue-light;
      }
    }
  }

  &__logo {
    margin-bottom: 10px;
    line-height: 0;
    width: 180px;
  }

  &__link {
    display: block;
    padding: 0.25rem 1.5rem;
    position: relative;
    text-decoration: none;
    @include fonts($color-blue-dark, $font-regular, 14);

    &::before {
      background-color: $color-blue-gray;
      border-radius: 0 rem(2) rem(2) 0;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      left: 0;
      top: 5px;
      transition: all 0.2s linear;
      width: 3px;
    }

    &:hover {
      background-color: transparent;
      color: $color-blue-gray;
      text-decoration: none;

      &::before {
        height: 70%;
      }
    }

    &.active {
      font-family: $font-semi-bold;

      &::before {
        background-color: $color-blue;
        height: 70%;
      }
    }
  }

  &__me {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 110px;
    @include fonts($color-blue-gray, $font-bold, 14);
  }

  &__menu {
    &-dropdown {
      background-color: $color-gray-light;
      border: 1px solid $color-gray-light;
      border-radius: rem(20);
      box-shadow: 0 2px 8px #0000001a;
      padding: 5px 10px 5px 8px !important;
      text-transform: uppercase;
      @include fonts($color-blue-gray, $font-semi-bold, 12);

      &:hover {
        background-color: $color-white;
        color: $color-blue-gray;
      }
    }

    &-items {
      border: 1px solid $color-gray-light;
      border-radius: rem(5);
      box-shadow: 0 2px 8px #0000001a;
      transform: translate3d(-85px, 35px, 0);
    }

    &.show {
      .header {
        &__menu-dropdown {
          background-color: $color-white;
          color: $color-blue-gray;

          .icon-menu::before {
            content: '\e908';
          }
        }
      }
    }
  }

  &__submenu {
    padding: 0 !important;
  }

  &__switch {
    display: inline-block;
    min-height: 19px;
    position: relative;
    padding-left: 32px;
    vertical-align: top;

    &::before {
      background-color: $color-blue;
      border: $color-blue solid 1px;
      border-radius: rem(23);
      content: '';
      display: block;
      height: 1rem;
      left: 0;
      pointer-events: all;
      position: absolute;
      top: 2px;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 1.75rem;
    }

    &::after {
      background: 50% / 50% 50% no-repeat;
      background-color: $color-white;
      border-radius: 0.5rem;
      content: '';
      display: block;
      height: calc(1rem - 4px);
      left: 13px;
      position: absolute;
      top: 4px;
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        left 0.15s ease-in-out;
      width: calc(1rem - 4px);
    }

    &--disabled {
      &::before {
        background-color: $color-gray-disabled;
        border: $color-gray-disabled solid 1px;
      }

      &::after {
        background-color: $color-blue-gray;
        left: 3px;
      }
    }
  }

  &__version {
    @include fonts($color-gray-input, $font-regular, 12);
  }
}
