// Colors
$color-blue: #006cff;
$color-blue-dark: #06152b;
$color-blue-dark-opacity: #06152bc4;
$color-blue-gray: #313a4f;
$color-blue-med: #005bd6;
$color-blue-light: #1d7dff;
$color-blue-semi: #6eacff;
$color-blue-td: #313d4f;
$color-blue-thin: #d1e5ff;
$color-black: #000;
$color-ligth-blue-black: #12131f;
$color-black-opacity: #0003;
$color-gray: #aeb3bf;
$color-gray-dark: #424242;
$color-gray-disabled: #acb2c1;
$color-gray-input: #8990a0;
$color-gray-opacity: #dce0ea40;
$color-gray-label: #5c6475;
$color-gray-light: #dce0ea;
$color-gray-sep: #8b919a;
$color-gray-white: #f9fafc;
$color-gray-blocked: #6c757d;
$color-gray-blocked-bg: #e9ecef;
$color-success: #c8fceb;
$color-success-dark: #47ddac;
$color-success-light: #e7fdf6;
$color-red: #c73c3c;
$color-red-light: #de4d4d;
$color-red-thin: #f8e3dd;
$color-white: #fff;
$color-green: #c7fcea;
$color-green-light: #47ddac;
$color-green-dark: #10a172;
$color-aquamarine: #009396;
$color-dark-aquamarine: #007678;
$color-yellow: #fde2bc;
$color-yellow-dark: #f9b959;
$color-purple-light: #ebeaff;
$color-orange: #e98054;

// Typography
$font-bold: 'ProximaNova-Bold', sans-serif;
$font-regular: 'ProximaNova-Regular', sans-serif;
$font-semi-bold: 'ProximaNova-SemiBold', sans-serif;
$font-icon: 'icomoon';
$font-icon-admin: 'icomoon-admin';

// Path Images
$img: '../../images/';
$img-background: '../../images/backgrounds/';
