.report {
  &__card {
    background-color: $color-white;
    border: 1px solid $color-gray-light;
    margin-right: rem(15);
    width: 25%;
    min-width: 128px;

    &:last-child {
      margin-right: 0;
    }

    &--full {
      width: 100%;
    }

    &-current {
      bottom: 0;
      border-color: transparent transparent $color-success-dark;
      border-style: solid;
      border-width: 0 7px 10px;
      display: inline-block;
      height: 15px;
      margin-left: 5px;
      width: 15px;

      &--down {
        border-color: transparent transparent $color-red-light;
      }
    }

    &-label {
      @include fonts($color-blue-gray, $font-bold, 28, 40);
    }

    &-title {
      background-color: $color-gray-light;
      border: none;
      border-radius: 0;
      padding: 10px 15px;
      @include fonts($color-blue-gray, $font-regular, 14);
    }

    &-value {
      @include fonts($color-success-dark, $font-semi-bold, 16, 22);

      &--down {
        color: $color-red-light;
      }
    }
  }

  &__cards {
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
    min-height: 145px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__details {
    background-color: transparent;
    border: none;
    @include fonts($color-blue, $font-semi-bold, 14);

    &:focus,
    &:hover {
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: $color-blue-dark;
      outline: none;
      text-decoration: underline;
    }
  }

  &__graph {
    height: 500px;
  }

  &__nav {
    border-bottom: 1px solid $color-gray-light;

    &-item {
      background-color: transparent;
      border: none;
      position: relative;
      padding: 10px 20px 20px;
      transition: color 0.15s ease;
      @include fonts($color-blue-gray, $font-semi-bold, 12, 18);

      &::after {
        background-color: $color-blue-light;
        bottom: -2px;
        content: '';
        display: block;
        height: 3px;
        left: 0;
        position: absolute;
        transition: all 0.2s linear;
        width: 0;
      }

      &:focus,
      &:hover {
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: $color-blue-med;
        outline: none;
      }

      &.current {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
