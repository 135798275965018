.scripts {
  &__back {
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: left;
    @include fonts($color-gray-label, $font-bold, 12, 18);

    &:active,
    &:focus,
    &:hover {
      background-color: transparent !important;
      border: none !important;
      color: $color-blue-light !important;
      text-decoration: none;
    }

    i.icon-back-arrow {
      position: relative;
      top: 2px;
    }
  }

  &__btn {
    align-items: center;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include fonts($color-white, $font-bold, 12);

    &--add {
      background-color: $color-gray-white;
      border-color: $color-gray-input;
      color: $color-blue-gray;

      &:active,
      &:focus {
        background-color: $color-gray-white;
        border-color: $color-gray-input;
        color: $color-blue-gray;
      }

      &:hover {
        background-color: $color-gray-light;
        border-color: $color-gray-light;
        color: $color-gray-label;
      }
    }

    &--full {
      height: 40px;
    }

    &--section {
      background-color: $color-blue-light;
      border-color: $color-blue-light;

      &:active,
      &:focus {
        background-color: $color-blue-light;
        border-color: $color-blue-light;
      }

      &:hover {
        background-color: $color-blue-med;
        border-color: $color-blue-med;
        color: $color-white;
      }

      i {
        font-size: rem(16);
      }
    }

    &--settings {
      background-color: $color-blue-dark;
      border-color: $color-blue-dark;

      &:active,
      &:focus {
        background-color: $color-blue-dark;
        border-color: $color-blue-dark;
      }

      &:hover {
        background-color: $color-gray-disabled;
        border-color: $color-gray-disabled;
        color: $color-white;
      }
    }
  }

  &__description {
    @include fonts($color-blue-gray, $font-regular, 14, 16);
  }

  &__card {
    &-create {
      background-color: $color-blue-thin;
      border-radius: rem(3);
    }

    &-header {
      background-color: white;
    }

    &-info {
      display: inline-block;
      text-transform: capitalize;
      @include fonts($color-blue-dark, $font-semi-bold, 15, 22);

      &--create {
        color: $color-blue-med;
        font-family: $font-bold;
      }
    }

    &-link {
      text-decoration: underline;
      @include fonts($color-blue-dark, $font-bold, 12, 12);

      &--delete {
        color: $color-red;

        &:hover {
          color: $color-red-light;
        }
      }

      &--create {
        color: $color-blue-med;
      }
    }

    &-move {
      color: $color-blue-gray;
      display: inline-block;
      font-size: rem(21);
      vertical-align: middle;
    }

    &-order {
      @include fonts($color-blue-gray, $font-bold, 13, 22);
    }

    &-title {
      margin-bottom: 0;
      @include fonts($color-blue-dark, $font-semi-bold, 22);
    }

    &--section {
      border: 1px solid $color-gray-light;
      border-radius: rem(5);

      &-dark {
        background-color: $color-gray-opacity;
      }

      &-light {
        background-color: $color-gray-white;
      }

      .card-body {
        background-color: transparent;
      }

      .card-header {
        background-color: transparent;
        border: none;
      }
    }

    &--subgroup {
      background-color: white;
      border: 1px solid $color-gray-light;
      border-radius: rem(5);
      position: relative;

      &::before {
        background-color: $color-gray-label;
        border-radius: rem(5) 0 0 rem(5);
        content: '';
        height: 58px;
        position: absolute;
        left: 0;
        top: -1px;
        width: 9px;
      }
    }
  }

  &__children {
    position: relative;

    &::before {
      border-bottom: 1px solid #dce0ea;
      display: block;
      content: '';
      left: -9px;
      position: absolute;
      bottom: 20px;
      width: 25px;
    }
  }

  &__parent {
    position: relative;

    &::before {
      border-left: 1px solid $color-gray-light;
      display: block;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: -20px;
    }
  }

  &__remove {
    background-color: $color-gray-white;
    border: 1px solid $color-gray-input;
    color: $color-blue-td;
    height: 40px;
    line-height: rem(39);
    font-size: rem(14);
    width: 40px;

    &:hover {
      background-color: $color-gray-light;
      border-color: $color-gray-light;
      color: $color-gray-label;
    }
  }

  &__subtitle {
    @include fonts($color-gray-label, $font-semi-bold, 16, 22);

    .icon-call-bar {
      font-size: rem(13);
    }
  }

  &__title {
    @include fonts($color-blue-dark, $font-bold, 28, 40);

    &--section {
      @include fonts($color-gray-label, $font-bold, 13, 18);
    }
  }

  &__modal {
    .modal-body {
      background-color: $color-gray-white;
    }

    &-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &-close {
        font-size: rem(35);
      }
    }

    &-card {
      width: 150px;
      height: 126px;
      color: $color-blue-gray;
      font-weight: bold;

      .card-text {
        text-align: left;
      }
    }

    &-figure {
      font-size: rem(34);
      color: $color-blue-light;
      text-align: start;
    }
  }
}
