.history {
  &__author {
    margin-bottom: 0;
    @include fonts($color-gray-label, $font-semi-bold, 14, 16);

    strong {
      color: $color-blue-dark;
      font-family: $font-bold;
    }

    &--group {
      font-size: rem(13);
      line-height: rem(15);
    }
  }

  &__container {
    background-color: $color-gray-opacity;

    &--bottom {
      border-radius: 0 0 rem(5) rem(5);
      padding: rem(5) rem(15) rem(10);
    }

    &--top {
      border-radius: rem(5) rem(5) 0 0;
      padding: rem(10) rem(15) 0;
    }
  }

  &__item {
    padding-left: rem(35);
    position: relative;

    &::after {
      background-color: $color-blue-semi;
      content: '';
      height: 100%;
      left: 14px;
      position: absolute;
      top: 22px;
      width: 1px;
    }

    &::before {
      background-color: $color-blue-semi;
      border-radius: 50%;
      color: $color-white;
      content: ' ';
      font-family: $font-icon;
      font-size: rem(12);
      height: 20px;
      line-height: 20px;
      left: 4px;
      position: absolute;
      text-align: center;
      top: 25%;
      width: 20px;
      z-index: 1;
    }

    &:first-child {
      &::before {
        background-color: $color-blue-light;
        box-shadow: 0 0 0 4px $color-blue-thin;
      }

      /* stylelint-disable-next-line selector-class-pattern */
      .history__container {
        background-color: $color-blue-thin;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &--group {
      &::after {
        top: 12px;
      }

      &::before {
        height: 10px;
        line-height: 10px;
        left: 9px;
        top: 12px;
        width: 10px;
      }
    }
  }

  &__message {
    margin-bottom: 0;
    @include fonts($color-blue-td, $font-semi-bold, 13, 13);

    strong {
      color: $color-blue-dark;
      font-family: $font-bold;
    }

    &--group {
      font-size: rem(12);
      line-height: rem(14);
      margin-top: rem(2);
    }
  }

  &__script {
    display: inline;
    text-decoration: underline;
    @include fonts($color-blue-td, $font-semi-bold, 13, 13);
  }

  &__wrapper {
    position: relative;
  }
}
