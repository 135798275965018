/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */
.ui {
  &__button {
    border-radius: rem(5);
    border-style: solid;
    border-width: 1px;
    font-family: $font-semi-bold;
    font-size: rem(14);
    transition: all 0.25s ease;

    &--copy-clipboard {
      font-size: rem(24);
      width: rem(35px);
      height: rem(35px);
      padding: 0;
    }

    &--default {
      background-color: $color-blue-dark;
      border-color: $color-blue-dark;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-blue-gray;
        border-color: $color-blue-gray;
        color: $color-white;
      }
    }

    &--call-now {
      background-color: $color-blue-dark;
      border-color: $color-blue-dark;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-gray-input;
        border-color: $color-gray-input;
        color: $color-white;
      }
    }

    &--confirm {
      background-color: $color-blue;
      border-color: $color-blue;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-blue-thin;
        border-color: $color-blue-thin;
        color: $color-blue;
      }
    }

    &--gray-light {
      background-color: $color-gray-white;
      border-color: $color-gray-input;
      color: $color-blue-gray;

      &:hover,
      &:focus {
        background-color: $color-gray-light;
        border-color: $color-gray-light;
        color: $color-gray-label;
      }
    }

    &--icon {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--last-sync {
      font-size: rem(12);
      line-height: normal;
      max-width: 100px;
    }

    &--login {
      background-color: $color-blue-dark;
      border-color: $color-blue-dark;
      border-radius: rem(8);
      color: $color-white;
      font-family: $font-regular;
      font-size: rem(16);
      height: 50px;

      &.disabled {
        background-color: $color-gray-disabled;
        border-color: $color-gray-disabled;
        color: $color-white;
      }

      &:hover,
      &:focus {
        background-color: $color-blue-gray;
        border-color: $color-blue-gray;
        color: $color-white;
      }
    }

    &--primary {
      background-color: $color-blue-light;
      border-color: $color-blue-light;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-blue-med;
        border-color: $color-blue-med;
        color: $color-white;
      }
    }

    &--nextech {
      font-size: rem(11);
      line-height: normal;
      max-width: 62px;
    }

    &--restart {
      background-color: $color-gray-light;
      border-color: $color-gray-light;
      color: $color-blue-dark;

      &:hover,
      &:focus {
        background-color: $color-blue-light;
        border-color: $color-blue-light;
        color: $color-white;
      }
    }

    &--reject {
      background-color: $color-red-light;
      border-color: $color-red-light;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-red-thin;
        border-color: $color-red-thin;
        color: $color-red-light;
      }
    }

    &--thin {
      background-color: $color-blue-thin;
      border-color: $color-blue-thin;
      color: $color-blue-dark;

      &:hover,
      &:focus {
        background-color: $color-blue-dark;
        border-color: $color-blue-dark;
        color: $color-white;
      }

      &-blue {
        &:hover,
        &:focus {
          background-color: $color-blue;
          border-color: $color-blue;
          color: $color-white;
        }
      }
    }

    &--white {
      background-color: $color-white;
      border-color: $color-gray-light;
      color: $color-gray-label;

      &:hover,
      &:focus {
        background-color: $color-gray-light;
        border-color: $color-gray-light;
        color: $color-gray-label;
      }
    }
  }

  &__bg {
    &-gray {
      background-color: $color-gray-white;
    }

    &-white {
      background-color: $color-white;
    }
  }

  &__color {
    &-blue-dark {
      color: $color-blue-dark;
    }

    &-blue-gray {
      color: $color-blue-gray;
    }
  }

  &__client {
    background-color: $color-white;
    border: 1px solid $color-gray-light;
    border-radius: rem(5);
    transition: all 0.25s ease;

    &:hover {
      background-color: $color-blue-thin;
      border-color: $color-blue-thin;
    }
  }

  &__date-tooltip {
    cursor: pointer;
  }

  &__dropdown-select {
    &-toggle {
      background-color: transparent !important;
      border: none;
      color: $color-blue-gray !important;
      font-family: $font-regular;
      font-size: rem(14);
      text-align: left;

      &:hover,
      &:active,
      &:focus {
        background-color: white !important;
        color: #6c757d !important;
        border-width: 1px !important;
        border-color: #dce0ea !important;
      }

      &::after {
        float: right;
        margin: 6px 0 0;
      }
    }

    &-menu {
      max-height: 500px;
      overflow-y: scroll;
    }

    &-item {
      text-align: left;
      @include fonts($color-blue-gray, $font-regular, 14);
    }
  }

  &__filter {
    &-applied {
      @include fonts($color-gray-input, $font-regular, 12, 13);
    }

    &-search {
      margin-bottom: 0;
      position: relative;

      &::after {
        color: $color-blue;
        content: '\e915';
        display: block;
        font-family: $font-icon !important;
        font-size: rem(17);
        position: absolute;
        top: 7px;
        right: 8px;
      }
    }

    &-tags {
      &-title {
        @include fonts($color-gray-label, $font-semi-bold, 12, 12);
      }

      &-item {
        @include fonts($color-gray-input, $font-regular, 11, 11);
      }

      &-modal {
        background-color: $color-white;
        border: 1px solid #dce0ea;
        border-radius: 0.3125rem;
        box-shadow: 0 2px 8px #0000001a;
        opacity: 0;
        position: absolute;
        transition: all 0.25s ease;
        z-index: 10;

        &.active {
          display: block;
          opacity: 1;
        }
      }

      &-tag {
        border-style: solid;
        border-width: 1px;
        border-radius: rem(5);
        cursor: pointer;
        position: relative;
        @include fonts($color-blue-gray, $font-semi-bold, 11, 11);

        &:active,
        &:focus,
        &:hover {
          color: $color-blue-gray !important;
        }

        &--source {
          background-color: #f9e5ff;
          border-color: #f9e5ff;

          &:active,
          &:focus,
          &:hover {
            background-color: #f9e5ff !important;
            border-color: #f9e5ff !important;
          }
        }

        &--groups {
          background-color: #cdfafe;
          border-color: #cdfafe;

          &:active,
          &:focus,
          &:hover {
            background-color: #cdfafe !important;
            border-color: #cdfafe !important;
          }
        }

        &--campaign {
          background-color: #f9b959;
          border-color: #f9b959;

          &:active,
          &:focus,
          &:hover {
            background-color: #f9b959 !important;
            border-color: #f9b959 !important;
          }
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid $color-gray-light;
  }

  &__form {
    &-group {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
    }

    &-icons {
      background-color: transparent;
      border: none;
      color: $color-gray-sep;
      font-size: rem(18);
      position: absolute;
      padding: 0;
      right: 5%;
      top: 32%;

      &--button {
        &:focus,
        &:hover {
          background-color: transparent;
          border: none;
          box-shadow: none;
          outline: none;
        }
      }

      &--radius {
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        color: $color-white;
        font-size: rem(7);
        height: 18px;
        text-align: center;
        width: 18px;

        &.correct {
          background-color: $color-blue-light;
          border-color: $color-blue-light;
          line-height: 17px;
        }

        &.error {
          background-color: $color-red-light;
          border-color: $color-red-light;
          line-height: 18px;
        }
      }
    }

    &-scroll-container {
      height: 70vh;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__input {
    background-color: $color-white;
    border: 1px solid $color-gray-light;
    border-radius: rem(5);
    cursor: text;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    transition: none;
    width: 100%;
    @include fonts($color-blue-gray, $font-regular, 14);

    &::placeholder {
      color: $color-blue-gray;
    }

    &:focus,
    &:hover {
      border-color: $color-gray-light;
      box-shadow: none;
      outline: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $color-blue-gray;
      border: 1px solid $color-gray-light;
      box-shadow: 0 0 0 1000px $color-white inset;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      background-color: $color-gray-opacity;
      cursor: not-allowed;
    }

    &--radio {
      display: inline-block;
      height: inherit;
      line-height: inherit;
      padding-left: 0;
      padding-right: 0;
      vertical-align: top;
      width: inherit;
    }

    &--date,
    &--select,
    &--multiselect {
      cursor: pointer;
    }

    &--date-time {
      cursor: pointer;

      .react-datepicker__navigation--next {
        right: 125px;
      }
    }

    &--multiselect {
      cursor: pointer;
      position: relative;
      padding-left: 0;
      padding-right: 0;

      .rw-multiselect-tag {
        background-color: $color-gray-light;
        margin-top: 0 !important;
        font-family: $font-semi-bold;
      }

      .rw-multiselect-taglist {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
      }

      .rw-select {
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 27px;
        z-index: 2;

        .rw-btn-select {
          display: block;
          height: 100%;
          width: 100%;
        }
      }

      .rw-input-reset {
        display: none !important;
      }

      .rw-widget-container {
        background-color: transparent;
        border: none;
        box-shadow: none;
        display: block;
        height: 100%;
        overflow: inherit;
        width: 100%;

        &:hover {
          background-color: transparent;
          border: none;
          box-shadow: none;
        }

        div {
          display: block;
          overflow-x: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    &--phone {
      .form-control {
        background-color: transparent;
        border: 0;
        padding: 0 10px;
        height: 100%;
        width: 100%;
        @include fonts($color-blue-gray, $font-regular, 14);

        &:disabled {
          background-color: $color-gray-opacity;
          cursor: not-allowed;
        }

        &:focus {
          border: none;
          box-shadow: none;
        }
      }

      .special-label {
        display: none;
      }
    }

    &--typeahead {
      height: 40px;
      transition: none;
      width: 100%;

      .css-1xc3v61-indicatorContainer {
        cursor: pointer;
      }

      .css-13cymwt-control {
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 100%;

        &:hover,
        &:focus {
          border: none;
          box-shadow: none;
        }
      }

      .css-1fdsijx-ValueContainer {
        padding: 0;
      }

      .css-t3ipsp-control {
        background-color: transparent;
        border: none;
        box-shadow: none;

        &:hover,
        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }

    &--switch {
      height: 24px;
    }

    &--switch:checked ~ .ui__label--switch::after {
      background-color: $color-gray-white !important;
    }

    &--switch:focus:not(:checked) ~ .ui__label--switch::before {
      border-color: $color-gray-disabled !important;
      box-shadow: none;
    }

    &--textarea {
      padding-top: 5px;
      height: 110px;
      resize: none;
    }
  }

  &__icon {
    border-style: solid;
    border-radius: 50%;
    border-width: 1px;
    display: inline-block;
    text-align: center;

    &--check {
      background-color: $color-blue-light;
      border-color: $color-blue-light;
      width: 23px;

      i {
        color: $color-white;
        font-size: 10px;
        line-height: 18px;
      }
    }

    &--menu {
      background-color: $color-blue-gray;
      border-color: $color-blue-gray;
      width: 28px;

      i {
        color: $color-white;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  &__label {
    cursor: pointer;
    text-transform: uppercase;
    @include fonts($color-blue-td, $font-semi-bold, 12, 18);

    &-check {
      cursor: pointer;
      @include fonts($color-blue-td, $font-regular, 14, 22);
    }

    &--error {
      cursor: text;
      text-transform: inherit;
      @include fonts($color-red, $font-regular, 10, 11);
    }

    &--switch {
      text-transform: capitalize;
      @include fonts($color-blue-td, $font-regular, 14, 18);

      &::after {
        background-color: $color-blue-gray !important;
      }

      &::before {
        background-color: $color-gray-disabled;
        border-color: $color-gray-disabled;
      }
    }

    &--required::after {
      content: ' *';
      @include fonts($color-red-light, $font-regular, 12, 18);
    }

    &--radio {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: top;
    }

    &--widget {
      text-align: left;
      text-transform: inherit;
      @include fonts($color-blue-td, $font-bold, 14, 14);
    }
  }

  &__loader {
    position: relative;
    width: 100%;

    &--widget {
      min-height: 50px;
      padding-top: 20px;
    }

    &--wrapper {
      font-size: rem(44);
      height: 100vh;
    }
  }

  &__main {
    height: 100%;

    &--call-bar {
      padding-top: 65px;

      .script__form {
        height: 70vh;
      }
    }
  }

  &__message {
    border-radius: rem(5);
    font-family: $font-bold;
    font-size: rem(14);
    line-height: rem(22);
    margin-bottom: 15px;
    padding: 19px 20px;

    &--error {
      background-color: $color-red-light;
      color: $color-red-thin;
    }

    &--success {
      background-color: $color-success;
      color: $color-gray-input;
    }
  }

  &__modal {
    &--lg {
      max-width: 600px;
    }

    &--md {
      max-width: 525px;
    }

    &--sm {
      max-width: 400px;
    }

    &-contact {
      &--scroll {
        height: 150px;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-info {
        border-bottom: 1px solid $color-gray-light;
        @include fonts($color-blue-light, $font-bold, 14, 16);
      }

      &-unknown {
        @include fonts($color-blue-light, $font-bold, 14, 16);
      }

      small {
        @include fonts($color-blue-td, $font-semi-bold, 13, 15);
      }
    }

    &-close {
      background-color: transparent;
      border: none;
      color: $color-blue-td;
      font-size: rem(14);

      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
        border: none;
        color: $color-blue-td;
      }
    }

    &-figure {
      background-color: $color-blue-thin;
      border-radius: 50%;
      color: $color-blue-light;
      font-size: rem(45);
      height: 126px;
      line-height: 138px;
      text-align: center;
      width: 126px;

      &--border {
        background-color: $color-blue-gray;
        border: 1px solid $color-blue-gray;
        color: $color-white;
        position: relative;

        &::after,
        &::before {
          animation-duration: 2s;
          animation-fill-mode: both;
          animation-iteration-count: infinite;
          animation-name: flash;
          border: 1px solid $color-gray-input;
          border-radius: 50%;
          content: '';
          display: block;
          height: 144px;
          left: -10px;
          position: absolute;
          top: -10px;
          width: 144px;
        }

        &::after {
          animation-duration: 1.5s;
          height: 134px;
          left: -5px;
          top: -5px;
          width: 134px;
        }
      }

      &--delete {
        background-color: $color-red-thin;
        color: $color-red-light;
      }

      &--blue {
        background-color: $color-blue-thin;
        color: $color-blue-light;
      }

      &--widget {
        background-color: transparent;
        border-radius: 0;
        color: $color-blue-light;
        font-size: rem(24);
        height: auto;
        line-height: inherit;
        text-align: left;
        width: auto;

        i {
          margin-bottom: 15px;
        }

        .icon-admin-dropdown {
          font-size: rem(45);
          margin-bottom: 0;
        }

        .icon-admin-open-answer {
          font-size: rem(30);
          margin-bottom: 15px;
        }
      }
    }

    &-number {
      @include fonts($color-blue-td, $font-bold, 22, 30);
    }

    .modal-body {
      padding: 0;
    }

    .modal-content {
      border-radius: rem(5);
    }

    .modal-close {
      background-color: transparent;
      border: none;
      color: $color-blue-gray;
      font-size: rem(15);
      padding: 0;
      position: absolute;
      right: 2%;
      transition: color 0.25s ease-in-out;
      top: 5%;

      &:active,
      &:focus,
      &:hover {
        background-color: transparent !important;
        border: none !important;
        color: $color-blue-dark !important;
      }
    }
  }

  &__paragraph {
    @include fonts($color-blue-td, $font-regular, 16, 18);

    &-modal {
      @include fonts($color-blue-td, $font-regular, 16, 18);

      a {
        color: $color-blue-light;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__select-widget {
    background-color: $color-white;
    border: 1px solid $color-gray-light;
    border-radius: rem(5);
    height: 130px;
    transition: all 0.2s ease-in-out;
    width: 150px;

    &:active,
    &:focus,
    &:hover {
      background-color: $color-blue-light;
      border-color: $color-blue-light;

      .ui__label--widget,
      .ui__modal-figure--widget {
        color: $color-white;
      }
    }
  }

  &__sep {
    border-color: $color-gray-sep;
    margin: 0;

    &-border {
      border-radius: rem(5);
    }

    &-vertical {
      &--bottom {
        border-bottom: 1px solid $color-gray-light;
      }

      &--full {
        border: 1px solid $color-gray-light;
      }

      &--left {
        border-left: 1px solid $color-gray-light;
      }

      &--right {
        border-right: 1px solid $color-gray-light;
      }

      &--top {
        border-top: 1px solid $color-gray-light;
      }
    }

    &--light {
      border-color: $color-gray-light;
    }
  }

  &__spinner {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;

    &::after {
      animation: jcn-loader-back 1.5s linear infinite;
      border: medium solid $color-blue-gray;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-radius: 50%;
      content: ' ';
      display: inline-block;
      height: 2em;
      width: 2em;
      position: absolute;
    }

    &::before {
      animation: jcn-loader 1.5s linear infinite;
      border: medium solid $color-blue-semi;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-radius: 50%;
      content: ' ';
      display: inline-block;
      position: absolute;
      height: 2em;
      width: 2em;
    }
  }

  &__scroll {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &--display {
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #dce0ea;
        border-radius: rem(3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #8990a0;
        border-radius: rem(3);
      }
    }

    &--nextech {
      height: 250px;
    }

    &--x {
      overflow-x: scroll;
    }

    &--y {
      overflow-y: scroll;
    }
  }

  &__area-scroll-sm {
    height: 130px;
  }

  &__table {
    &--attempt {
      tbody {
        tr {
          &:nth-child(even) {
            background-color: #f0f2f6;
          }

          td {
            text-align: center;
            @include fonts($color-blue-gray, $font-semi-bold, 10, 11);
          }
        }
      }
    }

    &--nextech {
      tbody {
        tr {
          background-color: $color-white;

          &:nth-child(even) {
            background-color: #eff2f7;
          }

          td {
            font-size: rem(14);
            line-height: rem(15);
            text-align: center;

            span {
              font-family: $font-semi-bold;
              font-size: rem(10);
              line-height: normal;
            }
          }
        }
      }

      thead {
        tr {
          background-color: $color-gray-light;

          th {
            text-align: center;
            text-transform: uppercase;
            @include fonts($color-blue-gray, $font-bold, 10, 11);
          }
        }
      }
    }

    &-tbody {
      tr {
        height: 1px;

        &:hover {
          background-color: $color-gray-opacity;
        }

        td {
          border-bottom: none;
          border-right: 1px solid $color-gray-light;
          border-top: none;
          height: inherit;
          vertical-align: middle;
          @include fonts($color-blue-td, $font-regular, 14);

          &:last-child {
            border-right: none;
          }

          &.ui__table-tbody--bottom {
            border-bottom: 0.5px solid $color-gray-light;
          }
        }
      }
    }

    &-thead {
      tr {
        background-color: $color-white;

        th {
          border-bottom: none;
          border-right: 1px solid $color-gray-light;
          border-top: none;
          text-transform: uppercase;
          vertical-align: middle;
          @include fonts($color-blue-gray, $font-bold, 12);

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    &-blocking {
      background-color: #aeb3bf14;

      td {
        color: $color-gray-input !important;

        a {
          color: $color-gray-input !important;
        }
      }
    }

    &-clickable {
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        td {
          color: $color-blue-semi !important;
        }
      }
    }

    &-dnc,
    &-inactive {
      background-color: $color-red-thin;
    }

    &-sold {
      background-color: $color-blue-thin !important;
    }

    &-duplicate {
      background-color: $color-purple-light;
    }

    &-delay {
      align-items: center;
      display: flex;
      height: 100%;
      padding: 0.75rem;

      &::before {
        content: '';
        background-color: black;
        border-radius: 50%;
        display: inline-block;
        height: 8px;
        margin: auto 8px auto 0;
        position: relative;
        width: 8px;
      }
    }

    &-first-delay {
      background-color: $color-green;

      &::before {
        background-color: $color-green-dark;
      }
    }

    &-html {
      p,
      li {
        margin-bottom: 0;
        font-family: $font-regular;

        b,
        strong {
          font-family: $font-bold;
        }
      }
    }

    &-nextech {
      &-error {
        background-color: $color-red-thin;

        span {
          @include fonts($color-blue-dark, $font-regular, 14, 15);
        }

        i {
          color: $color-red;
        }
      }

      &-link {
        background-color: transparent;
        border: none;
        padding: 0;
        text-decoration: underline;
        @include fonts($color-blue-dark, $font-semi-bold, 12, 13);

        &:focus,
        &:hover {
          background-color: transparent !important;
          border: none !important;
          color: $color-blue-dark;
        }
      }
    }

    &-second-delay {
      background-color: $color-yellow;

      &::before {
        background-color: $color-yellow-dark;
      }
    }

    &-single-delay {
      &::before {
        display: none;
      }
    }

    &-third-delay {
      background-color: $color-red-thin;

      &::before {
        background-color: $color-red;
      }
    }

    &-pagination {
      &-button {
        background-color: transparent !important;
        border: 1px solid transparent !important;
        cursor: pointer;
        color: $color-blue-gray;
        font-size: rem(14);
        line-height: rem(14);
        margin: 0;
        padding: 0 5px;

        &:disabled {
          cursor: default;
          color: $color-blue-gray;
          opacity: 0.7;

          &:hover {
            color: $color-blue-gray;
          }
        }

        &:focus,
        &:hover {
          color: $color-blue-semi;
          text-decoration: none !important;
        }

        &--first {
          position: relative;
          left: 8px;
        }

        &--last {
          position: relative;
          right: 8px;
        }
      }

      &-list {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: block;
          padding: 0 10px;

          &.active {
            a {
              color: $color-blue;
              font-family: $font-semi-bold;
            }
          }

          a {
            transition: all 0.1s ease-in-out;
            @include fonts($color-blue-gray, $font-regular, 14);

            &:hover {
              color: $color-blue-semi !important;
              text-decoration: none !important;
            }
          }
        }
      }

      &-select {
        width: 110px;
      }

      &-text {
        @include fonts($color-blue-gray, $font-semi-bold, 14);

        strong {
          font-family: $font-bold;
        }
      }
    }
  }

  &__timezone {
    padding-left: 45px;
    padding-right: 45px;

    &--lead-reschedule {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .rbt-input-main {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }

    &--user {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    .rbt-input-main {
      background-color: $color-white;
      border: 1px solid $color-gray-light;
      border-radius: rem(5);
      cursor: pointer;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      transition: none;
      width: 100%;
      @include fonts($color-blue-gray, $font-regular, 14);

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .rbt-menu {
      border: 1px solid $color-gray-light;
      border-radius: rem(5);

      .dropdown-item {
        p {
          margin-bottom: 0;
          @include fonts($color-blue-gray, $font-regular, 14);
        }
      }
    }
  }

  &__title {
    @include fonts($color-blue-dark, $font-bold, 36, 44);

    &-modal {
      @include fonts($color-blue-dark, $font-bold, 24, 32);
    }
  }

  &__semi-circular {
    &-graph {
      position: relative;
    }

    &-text {
      left: 0;
      position: absolute;
      top: 20px;
      width: 100%;
      @include fonts($color-blue-td, $font-semi-bold, 8, 10);

      strong {
        font-family: $font-bold;
        font-size: rem(22);
        line-height: rem(24);
      }
    }
  }

  &__subtitle {
    @include fonts($color-blue-dark, $font-bold, 20, 28);
  }

  &__toast {
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    color: $color-white;
    font-family: $font-semi-bold;
    font-size: rem(12);
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    text-align: center;
    width: 20px;

    &--error {
      background-color: $color-red-light;
      border-color: $color-red-light;
    }

    &--success {
      background-color: $color-blue-light;
      border-color: $color-blue-light;
    }

    &-icon {
      font-size: rem(54);
      color: $color-blue-light;
    }
  }

  &__tooltip {
    cursor: pointer;
  }

  &__border-bottom {
    border-bottom: $color-gray-light;
  }

  &__vertical-separator {
    border: none;
    border-left: 1px solid $color-gray-light;
    height: 100%;
  }

  &__modal-header {
    border-bottom: 0;
  }

  &__modal-subtitle {
    @include fonts($color-blue-gray, $font-regular, 14, 32);
  }
}

// External components
.ck {
  .ck-button__label {
    font-family: $font-semi-bold !important;
  }

  .ck-content {
    border-color: $color-gray-light !important;
    border-radius: 0 0 rem(5) rem(5) !important;
    @include fonts($color-blue-gray, $font-regular, 13, 18);

    &.ck-focused {
      box-shadow: none !important;
    }
  }

  .ck-toolbar {
    border-color: $color-gray-light !important;
    border-radius: rem(5) rem(5) 0 0 !important;
  }
}

.custom-select {
  cursor: pointer;
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .ui__input;
}

.h-90 {
  height: 90% !important;
}

.main-typehead {
  input[type='text'] {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .ui__input;
  }
}

.modal {
  background-color: $color-blue-dark-opacity;
}

.rbt-menu.dropdown-menu {
  .dropdown-item {
    color: $color-blue-gray;
    font-size: 14px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.Toastify {
  &__progress-bar {
    &--success {
      background: $color-success;
    }

    &--error {
      background: $color-red-light;
    }
  }

  &__toast-body > div:last-child {
    font-family: $font-regular !important;
    color: $color-blue-dark !important;
  }
}

.tooltip {
  &.bs-tooltip-right .arrow::before {
    border-right-color: $color-blue-thin;
  }

  &.bs-tooltip-left .arrow::before {
    border-left-color: $color-blue-thin;
  }

  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $color-blue-thin;
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $color-blue-thin;
  }

  .tooltip-inner {
    background-color: $color-blue-thin;
    color: $color-blue-dark;
    font-family: $font-semi-bold;
    font-size: rem(12);
    text-transform: uppercase;
  }
}

.w-40 {
  width: 40% !important;
}

.w-90 {
  width: 90% !important;
}

.icon-sorted {
  margin-left: 5px;
}

.icon-sort-none {
  top: -3px;
  position: relative;
  height: 3px;
  margin-left: 5px;

  .icon-admin-arrow-down {
    top: -5px;
    position: relative;
  }
}
