/* stylelint-disable selector-class-pattern */
.react-datepicker {
  border-radius: rem(5) !important;
  border: 1px solid $color-gray-light !important;

  &-popper {
    z-index: 3;
  }

  &-manager,
  &-wrapper {
    width: 100%;
  }

  &__input-container {
    display: flex !important;
    justify-content: center;
    width: 100%;
  }

  &__month-container {
    .react-datepicker__header {
      background: transparent;
      border: none;

      .react-datepicker__current-month {
        @include fonts($color-gray-dark, $font-regular, 14);
      }

      .react-datepicker__day-names {
        .react-datepicker__day-name {
          @include fonts($color-gray-input, $font-semi-bold, 12);
        }
      }

      .react-datepicker__current-month--hasMonthDropdown {
        display: none !important;
      }

      .react-datepicker__year-dropdown-container,
      .react-datepicker__month-dropdown-container {
        select {
          border: none;
          border-bottom: 1px solid $color-black-opacity;
          padding: 0 0 3px;
          margin: 0 0 0 5px;
          @include fonts($color-gray-input, $font-regular, 12);
        }
      }
    }

    .react-datepicker__month {
      .react-datepicker__day {
        @include fonts($color-gray-dark, $font-regular, 12);

        &.react-datepicker__day--selected {
          background-color: $color-blue-light;
          border-radius: 50%;
          @include fonts($color-white, $font-semi-bold, 12);
        }

        &.react-datepicker__day--keyboard-selected {
          background-color: $color-blue-semi;
          border-radius: 50%;
          @include fonts($color-white, $font-semi-bold, 12);
        }
      }

      .react-datepicker__day--disabled {
        @include fonts($color-gray, $font-semi-bold, 12);
      }
    }
  }

  &__navigation {
    &:focus {
      outline: none;
    }
  }

  &__time-container {
    border-left: 1px solid $color-gray-light;
    width: 120px;

    .react-datepicker__header {
      background: transparent;
      border: none;
    }

    .react-datepicker__header--time {
      .react-datepicker-time__header {
        @include fonts($color-gray-input, $font-regular, 14);
      }
    }

    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 100%;

        .react-datepicker__time-list {
          .react-datepicker__time-list-item {
            @include fonts($color-black, $font-regular, 12);

            &.react-datepicker__time-list-item--selected {
              background-color: $color-blue-light;
              @include fonts($color-white, $font-semi-bold, 12);
            }
          }
        }
      }
    }
  }

  &__triangle {
    display: none;
  }
}

.react-datepicker__reschedule {
  .react-datepicker {
    width: 100%;
  }

  .react-datepicker__time-container {
    width: 85px;
  }
}
