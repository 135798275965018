.main {
  background-color: $color-gray-white;
  height: 100%;
  overflow-y: scroll;
  padding-top: 78px;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
