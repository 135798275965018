.physician-referral {
  &__form {
    &-title {
      @include fonts($color-ligth-blue-black, $font-bold, 36, 36);
    }

    &-subtitle {
      @include fonts($color-ligth-blue-black, $font-bold, 20, 20);
    }

    &-button {
      background-color: $color-aquamarine;

      &:hover {
        background-color: $color-dark-aquamarine;
      }
    }
  }
}
