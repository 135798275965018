/* stylelint-disable selector-class-pattern */
.nextech {
  &__table-appoiments {
    .ui__table-thead {
      border-width: 1px;
      border-color: #dce0ea;

      th {
        &:first-child {
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }

        background-color: #dce0ea;
        border-right-color: #acb2c1;
      }
    }

    .ui__table-tbody {
      border-width: 1px;
      border-color: #acb2c1;

      tr {
        td {
          border-left-color: #acb2c1;
          border-right-color: #acb2c1;
        }

        &:last-child {
          td {
            border-bottom: 1px solid #acb2c1;

            &:first-child {
              border-bottom-left-radius: 10px;
            }

            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }
  }
}
