/* stylelint-disable selector-type-no-unknown */
/* stylelint-disable selector-class-pattern */
.dashboard {
  &-me {
    &__bars {
      height: 275px;

      text {
        font-family: $font-bold !important;
        font-size: rem(12) !important;
      }

      &-legend {
        @include fonts($color-blue-td, $font-semi-bold, 10, 12);

        &::before {
          background-color: $color-blue;
          border-radius: 50%;
          content: '';
          display: inline-block;
          height: 10px;
          margin-right: 10px;
          width: 10px;
        }

        &--team {
          &::before {
            background-color: $color-blue-semi;
          }
        }
      }
    }

    &__birthdays {
      height: 115px;
      overflow-x: hidden;
    }

    &__birthday {
      &:last-child {
        margin-bottom: 0 !important;
      }

      &-date {
        @include fonts($color-blue-gray, $font-semi-bold, 10, 13);
      }

      &-name {
        @include fonts($color-blue-gray, $font-bold, 12, 13);
      }
    }

    &__card {
      border: 1px solid $color-gray-light;
      border-radius: rem(5);

      &--gray {
        background-color: $color-gray-white;
      }

      &--extra-large {
        max-height: 100%;
        min-height: 100%;
      }

      &--large {
        max-height: 360px;
        min-height: 360px;
      }

      &--med {
        max-height: 180px;
        min-height: 180px;
      }

      &--tip {
        background-color: #d4e9ff;
      }

      &--small {
        max-height: 120px;
        min-height: 120px;
      }
    }

    &__compared-info {
      @include fonts($color-blue, $font-semi-bold, 10, 10);

      &--decrease {
        color: $color-red;
      }
    }

    &__compared-success {
      @include fonts($color-green-dark, $font-semi-bold, 10, 10);
    }

    &__fullname {
      @include fonts($color-blue-dark, $font-bold, 24);
    }

    &__tip {
      height: 100px;

      /* stylelint-disable-next-line no-descending-specificity */
      b,
      strong {
        font-family: $font-bold;
      }

      @include fonts($color-blue-dark, $font-regular, 12, 18);
    }

    &__info {
      @include fonts($color-gray-label, $font-bold, 14);

      &--blue {
        color: $color-blue;
      }
    }

    &__number {
      @include fonts($color-blue-dark, $font-semi-bold, 30, 30);

      small {
        font-family: $font-regular;
        font-size: rem(16);
      }

      &--percentage {
        font-size: 0.5rem;
        line-height: 0.5rem;
      }

      &--completed {
        color: $color-success-dark;
        font-size: rem(10);
        line-height: rem(12);
      }
    }

    &__hour {
      @include fonts($color-blue-dark, $font-semi-bold, 42, 42);

      small {
        font-size: rem(10);
        line-height: rem(8);
      }

      &--completed {
        font-size: rem(8);
        line-height: rem(8);
      }
    }

    &__page-title {
      @include fonts($color-blue-dark, $font-bold, 20, 18);

      &--subtitle {
        font-size: rem(11);
        line-height: normal;
      }
    }

    &__paragraph {
      /* stylelint-disable-next-line no-descending-specificity */
      b,
      strong {
        font-family: $font-bold;
      }

      @include fonts($color-blue-dark, $font-regular, 12, 18);
    }

    &__thumb {
      background-color: #c0c0c0;
      border-radius: 50%;
      box-shadow: 5px 5px 30px 0 $color-gray-light;
      height: 126px;
      width: 126px;

      &--small {
        height: 36px;
        width: 36px;
      }
    }

    &__things {
      height: 170px;

      &-item {
        border-bottom: 1px solid $color-gray-light;

        &:last-child {
          border-bottom: none;
        }

        p,
        li {
          @include fonts($color-blue-td, $font-semi-bold, 12);
        }

        p {
          margin-bottom: 5px;

          b,
          strong {
            font-family: $font-bold;
          }
        }

        ul {
          padding-left: rem(15);
          margin-bottom: rem(2);
        }
      }
    }

    &__title {
      @include fonts($color-blue-dark, $font-bold, 12);

      &--light {
        font-family: $font-semi-bold;
      }

      &--subtitle {
        text-transform: none;
        @include fonts($color-blue-dark, $font-regular, 8);
      }
    }
  }

  &-supervisor {
    &__card {
      &--large {
        max-height: 360px;
        min-height: 360px;
      }

      &--med {
        height: 100%;
        max-height: 200px;
        min-height: 200px;
      }

      &-performers {
        max-height: 60px;
        min-height: 60px;

        &-title {
          @include fonts($color-blue, $font-bold, 9, 13);
        }

        &-item {
          @include fonts($color-blue-td, $font-semi-bold, 9, 11);
        }
      }
    }

    &__pie {
      align-items: center;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;

      &-title {
        width: 45px;
        @include fonts($color-blue-dark, $font-semi-bold, 8, 11);

        &--md {
          font-size: rem(16);
          line-height: rem(18);

          small {
            font-size: rem(8);
            line-height: rem(10);
          }
        }
      }
    }

    &__satisfaction {
      &-tabs {
        gap: 5px;

        &-link {
          text-transform: uppercase;
          width: fit-content;
          @include fonts($color-blue-gray, $font-bold, 10, 10);

          &:hover,
          &.active {
            color: $color-blue-light !important;
            border-color: $color-blue-light !important;
            cursor: pointer;
          }
        }
      }

      &-card {
        background-color: #f5c3b5;
        border-radius: rem(16);
        width: 90px;
        @include fonts($color-blue-td, $font-semi-bold, 9, 9);

        &-text {
          @include fonts($color-blue-dark, $font-semi-bold, 10, 10);
        }

        &--neutral {
          background-color: #fde3bc;
        }

        &--satisfied {
          background-color: #b3f7e1;
        }
      }
    }

    &__sep {
      border-left: 1px solid $color-gray-light;
    }

    &__status {
      &-agent {
        border-bottom: 1px solid $color-gray-light;

        &:last-child {
          border-bottom: none;
        }
      }

      &-card {
        background-color: $color-orange;
        border-radius: rem(9);
        min-width: 45px;
        text-align: center;
        @include fonts(white, $font-bold, 9, 9);

        &--elder,
        &--1st {
          background-color: #006cff;
        }

        &--intermediate,
        &--2nd {
          background-color: #6eacff;
        }

        &--last {
          background-color: #e98054;
        }

        &--low,
        &--4th {
          background-color: #f9b959;
        }

        &--middle,
        &--3rd {
          background-color: #bab7ff;
        }

        &--md {
          width: 120px;
        }
      }

      &-contacted {
        border-bottom: 1px solid $color-gray-light;

        &:last-child {
          border-bottom: none;
        }
      }

      &-count {
        min-width: 35px;
        @include fonts($color-blue-td, $font-semi-bold, 10, 10);
      }

      &-info {
        padding-left: rem(5);
        @include fonts($color-blue-td, $font-semi-bold, 10, 10);

        &:hover {
          color: $color-blue;
          font-family: $font-bold;
          text-decoration: none;
        }

        &--split {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100px;
        }
      }

      &-last {
        height: 130px;
      }
    }
  }

  &__actions {
    background-color: transparent;
    border: none;
    padding: 0;
    text-decoration: none;
    transition: all 0.15s ease;
    @include fonts($color-blue-gray, $font-semi-bold, 16);

    &:active,
    &:focus,
    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;
      color: $color-blue-td !important;
    }
  }

  &__clock {
    width: 100%;
    @include fonts($color-blue-gray, $font-semi-bold, 14);
  }

  &__header {
    border-bottom: 2px solid $color-gray-opacity;
    margin: 0 20px;
    width: 100%;
  }

  &__last-sync {
    @include fonts($color-gray-input, $font-regular, 10, 14);

    &-time {
      @include fonts($color-gray-input, $font-bold, 10, 14);
    }
  }

  &__link {
    transition: all 0.25s ease;
    @include fonts($color-blue-gray, $font-regular, 14);

    &:focus,
    &:hover {
      background-color: transparent;
      color: $color-blue;
      font-family: $font-semi-bold;
      text-decoration: none;
    }
  }

  &__nav {
    position: relative;
    text-decoration: none;
    @include fonts($color-blue-gray, $font-semi-bold, 12, 18);

    &::before {
      background-color: $color-blue-light;
      bottom: -2px;
      height: 2px;
      content: '';
      position: absolute;
      left: 0;
      transition: all 0.15s linear;
      width: 0;
    }

    &:hover {
      color: $color-blue-light;
      text-decoration: none;

      &::before {
        width: 100%;
      }
    }

    &--active {
      color: $color-blue-light;

      &::before {
        width: 100%;
      }
    }
  }

  &__note {
    &-link {
      background-color: transparent;
      border: none;
      padding: 0;
      text-decoration: underline;
      transition: all 0.15s ease;
      @include fonts($color-blue-gray, $font-semi-bold, 14);

      &:active,
      &:focus,
      &:hover {
        background-color: transparent !important;
        box-shadow: none !important;
        color: $color-blue-td !important;
      }
    }
  }

  &__scroll {
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
