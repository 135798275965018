.group {
  &__description {
    @include fonts($color-blue-gray, $font-regular, 14, 16);
  }

  &__card {
    cursor: move;

    &-control {
      background-color: transparent;
      border: none;
      border-radius: 0;
      color: $color-blue;
      padding: 0;

      &:active,
      &:hover,
      &:focus {
        background-color: transparent !important;
        border: none !important;
        color: $color-blue !important;
      }
    }

    &-header {
      background-color: white;
    }

    &-icon {
      padding-left: 32px;
    }

    &-info {
      display: inline-block;
      @include fonts($color-blue-dark, $font-regular, 14, 22);

      &--medium {
        font-family: $font-semi-bold;
      }
    }

    &-link {
      display: inline-block;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
      text-decoration: underline;
      text-transform: uppercase;
      transition: all 0.25s ease-in-out;
      @include fonts($color-blue-dark, $font-bold, 12, 12);

      &--delete {
        color: $color-red;

        &:hover {
          color: $color-red-light;
        }
      }

      &--edit {
        color: $color-blue-light;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &-move {
      color: $color-blue-gray;
      display: inline-block;
      font-size: rem(21);
      vertical-align: middle;
    }

    &-title {
      margin-bottom: 0;
      @include fonts($color-blue-dark, $font-semi-bold, 22);
    }

    &--subgroup {
      background-color: white;
      border: 1px solid $color-gray-light;
      border-radius: rem(5);
      position: relative;

      &::before {
        background-color: $color-gray-label;
        border-radius: rem(5) 0 0 rem(5);
        content: '';
        height: 58px;
        position: absolute;
        left: 0;
        top: -1px;
        width: 9px;
      }

      &-basic {
        cursor: default;
      }
    }
  }

  &__message {
    background-color: white;
    border: 1px solid $color-gray-light;
    border-radius: rem(5);
    bottom: 15px;
    padding: 20px 18px;
    position: fixed;
    left: 0.5%;
    width: 99%;
    z-index: 100;

    &-description {
      margin-bottom: 0;
      @include fonts($color-blue-gray, $font-regular, 14, 14);
    }

    &-icon {
      background-color: $color-blue-light;
      border-radius: 50%;
      color: white;
      font-size: rem(22);
      height: 54px;
      line-height: 56px;
      margin-bottom: 0;
      text-align: center;
      width: 54px;
    }

    &-title {
      margin-bottom: 0;
      margin-top: 0;
      @include fonts($color-blue-gray, $font-semi-bold, 16, 22);
    }
  }

  &__title {
    @include fonts($color-blue-dark, $font-bold, 28, 40);
  }
}
