.script {
  &__blocked {
    button[type='button'],
    label,
    input,
    select,
    .css-b62m3t-container {
      pointer-events: none;
    }

    button[type='button'],
    input,
    select,
    .css-b62m3t-container {
      background-color: $color-gray-blocked-bg !important;
      color: $color-gray-blocked !important;
      opacity: 0.7;
    }
  }

  &__label {
    @include fonts($color-gray-label, $font-semi-bold, 12, 14);

    &--value {
      @include fonts($color-blue-gray, $font-bold, 16, 22);
    }
  }

  &__description {
    @include fonts($color-blue-td, $font-regular, 14, 16);

    &--required::after {
      content: ' *';
      @include fonts($color-red-light, $font-regular, 14, 16);
    }

    a {
      display: inline-block;
      margin-bottom: rem(10);
      @include fonts($color-blue-dark, $font-semi-bold, 14, 16);
    }

    p {
      margin-bottom: rem(5);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__form {
    height: 70vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__radio {
    display: inline-block;
    margin-right: rem(5px);
    vertical-align: middle;

    .custom-control-label {
      cursor: pointer;
      @include fonts($color-blue-gray, $font-regular, 14);
    }
  }

  &__wrapper {
    background-color: $color-gray-white;
    border-radius: rem(5);
    margin-bottom: rem(10);
    margin-top: rem(10);
    padding: rem(20);

    &--danger {
      background-color: $color-red-thin;
    }

    &.date,
    &.radio,
    &.input,
    &.insurance-select,
    &.location-select,
    &.multi-select,
    &.secondary-insurance-select,
    &.source-select,
    &.referring-physicians,
    &.phone-number {
      background-color: $color-white;
      border-radius: 0;
      margin-bottom: 0;
      margin-top: 0;
      padding: rem(10) rem(20);

      .script {
        &__description {
          margin-bottom: rem(10);
          @include fonts($color-blue-td, $font-semi-bold, 14, 18);
        }
      }
    }

    &.single-select {
      .script {
        &__description {
          margin-bottom: rem(10);
          @include fonts($color-blue-td, $font-semi-bold, 14, 18);
        }
      }
    }

    &.referring-physicians {
      background-color: $color-gray-white;
    }

    &.lead-verified {
      background-color: white;

      /* stylelint-disable-next-line selector-class-pattern */
      .script__description {
        display: none;
      }
    }
  }
}
