@import '~bootstrap/scss/bootstrap';
@import '~react-widgets/dist/css/react-widgets.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~normalize.css/normalize.css';
@import '~animate.css/animate.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

// CONFIG
@import 'modules/vars';
@import 'modules/app';
@import 'modules/icons';

// CONFIG MODULES
@import 'modules/mixins';
@import 'modules/typography';
@import 'modules/animation';

// COMPONENTS
@import 'components/ui';
@import 'components/date-picker';
@import 'components/login';
@import 'components/header';
@import 'components/main';
@import 'components/empty-state';
@import 'components/dashboard';
@import 'components/call-bar';
@import 'components/lead';
@import 'components/note';
@import 'components/scripts';
@import 'components/history';
@import 'components/reports';
@import 'components/admin-group';
@import 'components/admin-script';
@import 'components/time-legend';
@import 'components/satisfaction';
@import 'components/physician-referral';
@import 'components/nextech';
