/* stylelint-disable scss/at-rule-conditional-no-parentheses */
@mixin fonts($color, $family, $size, $line-height: null, $weight: normal) {
  color: $color;
  font-family: $family;
  font-size: rem($size);
  font-weight: $weight;

  @if ($line-height) {
    line-height: rem($line-height);
  }
}

@function rem($px, $context: 16) {
  @if (unitless($px)) {
    $px: calc($px * 1px);
  }

  @if (unitless($context)) {
    $context: calc($context * 1px);
  }

  @return calc($px / $context * 1rem);
}
