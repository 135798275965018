.call-bar {
  background-color: $color-blue;
  padding: 10px 30px;
  position: fixed;
  left: 0;
  top: 70px;
  width: 100%;
  z-index: 2;

  &__cta {
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    padding: 12px 20px;
    transition: all 0.25s ease;
    @include fonts($color-blue-thin, $font-semi-bold, 12, 18);

    &:disabled {
      color: $color-blue-thin;
    }

    &--active,
    &:hover {
      background-color: $color-blue-med;
      border: none;
      box-shadow: none;
      color: $color-blue-thin;
      outline: none;
      text-decoration: none;
    }

    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  &__icon {
    background-color: $color-blue-med;
    border-radius: 50%;
    color: white;
    height: 40px;
    font-size: rem(17);
    line-height: 43px;
    text-align: center;
    width: 40px;

    &--border {
      border: 1px dashed $color-white;
    }
  }

  &__title {
    @include fonts($color-white, $font-semi-bold, 18);
  }

  &__value {
    @include fonts($color-blue-thin, $font-semi-bold, 12);

    a {
      color: $color-blue-thin;
      text-decoration: underline;
    }
  }
}
