@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../../fonts/ProximaNova-Bold.eot');
  src: url('../../fonts/ProximaNova-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('../../fonts/ProximaNova-Bold.woff') format('woff'),
    url('../../fonts/ProximaNova-Bold.ttf') format('truetype'),
    url('../../fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../../fonts/ProximaNova-Regular.eot');
  src: url('../../fonts/ProximaNova-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../../fonts/ProximaNova-Regular.woff') format('woff'),
    url('../../fonts/ProximaNova-Regular.ttf') format('truetype'),
    url('../../fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-SemiBold';
  src: url('../../fonts/ProximaNova-Semibold.eot');
  src: url('../../fonts/ProximaNova-Semibold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova-Semibold.woff2') format('woff2'),
    url('../../fonts/ProximaNova-Semibold.woff') format('woff'),
    url('../../fonts/ProximaNova-Semibold.ttf') format('truetype'),
    url('../../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?u2y7y1');
  src: url('../../fonts/icomoon.eot?u2y7y1#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?u2y7y1') format('truetype'),
    url('../../fonts/icomoon.woff?u2y7y1') format('woff'),
    url('../../fonts/icomoon.svg?u2y7y1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
