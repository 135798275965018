.animate-spin {
  animation: spin 2s infinite linear;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}

@keyframes jcn-loader {
  0% {
    transform: rotate(135deg);
  }

  100% {
    transform: rotate(495deg);
  }
}

@keyframes jcn-loader-back {
  0% {
    transform: rotate(135deg);
  }

  100% {
    transform: rotate(-225deg);
  }
}
