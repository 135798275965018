.login {
  height: 100vh;

  &__banner {
    object-fit: cover;
  }

  &__icon {
    background-color: $color-gray-white;
    border: 1px solid $color-gray-input;
    border-radius: 50%;
    display: inline-block;
    font-size: 18px;
    height: 18px;
    vertical-align: sub;
    width: 18px;

    &.icon-correct {
      background-color: $color-white;
      border: none;
      border-radius: 0;
      color: $color-success-dark;
      vertical-align: initial;
    }
  }

  &__input {
    appearance: none;
    background-color: $color-white;
    background-clip: padding-box;
    border: 1px solid $color-gray-input;
    border-radius: rem(8);
    cursor: pointer;
    height: calc(3.5rem + 2px);
    line-height: 1.25;
    padding: 1rem 2rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    @include fonts($color-blue-gray, $font-semi-bold, 16);

    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      opacity: 0.65;
      transform: scale(0.9) translateY(-0.5rem) translateX(0.15rem);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $color-blue-gray;
      border: 1px solid $color-gray-input;
      box-shadow: 0 0 0 1000px $color-white inset;
    }
  }

  &__label {
    border: 1px solid transparent;
    color: $color-gray-input;
    font-family: $font-semi-bold;
    left: 0;
    height: 100%;
    padding: 1rem 2rem;
    position: absolute;
    pointer-events: none;
    top: 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    transform-origin: 0 0;
  }

  &__link {
    background-color: transparent;
    border: none;
    padding: 0;
    transition: color 0.25s ease-in-out;
    @include fonts($color-blue-gray, $font-semi-bold, 14, 20);

    &:active,
    &:focus,
    &:hover {
      background-color: transparent !important;
      border: none !important;
      color: $color-blue-dark !important;
      box-shadow: none !important;
      outline: none;
    }

    &--underline {
      text-decoration: underline;
    }
  }

  &__logo {
    img {
      width: 80%;
    }
  }

  &__message {
    @include fonts($color-blue-gray, $font-regular, 14, 20);
  }

  &__paragraph {
    @include fonts($color-blue-gray, $font-regular, 14, 22);
  }

  &__sso-button {
    align-items: center;
    background-color: $color-blue-med !important;
    border: 1px solid $color-blue-med !important;
    border-radius: rem(5) !important;
    box-shadow: none !important;
    color: $color-white !important;
    display: flex;
    flex-direction: row;
    height: 50px;
    font-family: $font-semi-bold !important;
    font-size: rem(14) !important;
    justify-content: center;
    transition: all 0.25s ease;
    width: 100%;

    &__image {
      margin-right: 10px;
      width: 6%;
    }

    div {
      background-color: $color-blue-med !important;
    }

    &:active,
    &:focus,
    &:hover {
      background-color: $color-blue-med !important;
      border: 1px solid $color-blue-med !important;
      color: $color-white !important;
    }
  }

  &__title {
    @include fonts($color-blue-gray, $font-semi-bold, 16, 22);
  }

  &__version {
    bottom: 10px;
    cursor: default;
    position: absolute;
    width: 100%;
    @include fonts($color-blue-gray, $font-semi-bold, 12, 12);
  }

  .invalid-feedback {
    bottom: -18px;
    display: inline-block;
    position: absolute;
    padding-left: 10px;
    margin-top: 0;
    @include fonts($color-red-light, $font-regular, 12);
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .login {
    &__logo {
      img {
        width: 70%;
      }
    }
  }
}
