.lead {
  &__action {
    &-bar {
      background-color: $color-blue;
    }

    &-cta {
      background-color: transparent;
      border: none;
      text-transform: uppercase;
      padding: 0;
      transition: all 0.25s ease-in-out;
      @include fonts($color-blue-thin, $font-semi-bold, 12, 18);

      &:hover {
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: $color-blue-thin;
        outline: none;
        opacity: 0.8;
        text-decoration: none;
      }

      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__back {
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: left;
    @include fonts($color-gray-label, $font-semi-bold, 14, 18);

    &:active,
    &:focus,
    &:hover {
      background-color: transparent !important;
      border: none !important;
      color: $color-blue-light !important;
      text-decoration: none;
    }

    i.icon-back-arrow {
      position: relative;
      top: 2px;
    }
  }

  &__blocking {
    background-color: $color-gray-light;
    border-radius: rem(5);
    padding: 15px 20px;
    @include fonts($color-gray-label, $font-bold, 14, 22);
  }

  &__duplicate {
    border-color: $color-gray-light;
    border-radius: rem(5);

    &-body {
      background-color: $color-gray-white;
      height: 150px;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-header {
      background-color: $color-gray-light;
      border-bottom-color: $color-gray-light;
      border-radius: 0 !important;
    }

    &-link {
      text-decoration: underline;
      @include fonts($color-blue-dark, $font-semi-bold, 14, 18);
    }

    &-title {
      @include fonts($color-blue-dark, $font-bold, 12, 18);
    }

    &-value {
      @include fonts($color-blue-dark, $font-regular, 14, 18);

      strong {
        font-family: $font-bold;
      }
    }
  }

  &__label {
    @include fonts($color-blue-gray, $font-semi-bold, 16, 22);

    &--email {
      color: $color-gray-label;
      text-decoration: underline;

      &:focus,
      &:hover {
        color: $color-gray-dark;
      }
    }

    &--name {
      margin-bottom: 0;
      @include fonts($color-blue-dark, $font-bold, 28, 30);

      strong {
        color: $color-gray-label;
        font-size: rem(12);
        line-height: normal;
      }
    }

    &--value {
      @include fonts($color-gray-label, $font-semi-bold, 12, 18);
    }

    &--large {
      font-size: rem(18);
      line-height: rem(22);
    }

    &--reschedule {
      @include fonts($color-blue-td, $font-semi-bold, 12, 18);
    }

    a {
      color: $color-blue-gray;
    }
  }

  &__reschedule {
    &-button {
      background-color: transparent;
      border: 1px solid $color-blue-med;
      color: $color-blue-med;
      @include fonts($color-blue-med, $font-bold, 14);

      &:focus,
      &:hover {
        background-color: $color-blue !important;
        border: 1px solid $color-blue;
        color: $color-white;
      }
    }

    &--clear {
      color: $color-blue;
      border: 1px solid $color-blue;

      &:focus,
      &:hover {
        background-color: $color-blue-thin !important;
        border: 1px solid $color-blue-thin;
      }
    }

    &-container {
      background-color: $color-blue-thin;
      border-radius: rem(5);
      padding: 12px 15px;
    }

    &-date {
      @include fonts($color-black, $font-semi-bold, 12, 18);
    }

    &-label {
      @include fonts($color-blue-dark, $font-semi-bold, 12, 18);
    }

    &-modal {
      max-width: 550px;
    }

    &--disabled {
      opacity: 0.7;
    }
  }

  &__scroll {
    height: 70vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tabs {
    border-bottom: 1px solid $color-gray-light;

    .nav-item {
      padding-right: rem(16);
    }

    &-link {
      border: none;
      cursor: pointer;
      padding: 0 0 rem(15);
      text-transform: uppercase;
      transition: color 0.5s ease, border 0.15s linear;
      @include fonts($color-blue-td, $font-semi-bold, 12, 18);

      &:hover {
        border-color: transparent !important;
        color: $color-blue-dark !important;
      }

      &.active {
        border-color: transparent !important;
        border-bottom: 2px solid $color-blue-light !important;
        color: $color-blue-dark !important;
      }
    }
  }

  &__tag {
    background-color: $color-blue;
    border-radius: rem(10);
    @include fonts($color-white, $font-semi-bold, 10, 10);

    &--overdue {
      background-color: $color-orange;
    }
  }

  &__title {
    border-bottom: 1px solid $color-gray-light;
    padding-bottom: 13px;
    @include fonts($color-blue-gray, $font-semi-bold, 16, 22);
  }

  .ui {
    &__button--call-now,
    &__button--restart {
      font-family: $font-bold;

      i.icon-call {
        font-size: rem(16);
        position: relative;
        top: 3px;
      }
    }

    &__input {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .custom-select {
    padding-right: 30px !important;
  }
}
