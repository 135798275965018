* {
  box-sizing: border-box;
  outline: 0;
  text-decoration: none;
  text-rendering: optimizelegibility;
}

html {
  font-size: 16px;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-regular;
  font-size: 16px;
  height: 100%;
}

a {
  transition: all 0.15s ease-in-out;
}

button {
  &:active,
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

input,
select,
textarea {
  &:disabled {
    color: $color-gray-input !important;
  }
}

.d-contents {
  display: contents;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}
