.empty-state {
  &__message {
    p {
      margin-bottom: 5px;
      @include fonts($color-blue-td, $font-regular, 14, 20);

      a {
        color: $color-blue-td;
        font-family: $font-semi-bold;
        text-decoration: underline;

        &:hover {
          color: $color-blue;
        }
      }
    }
  }

  &__title {
    @include fonts($color-blue-dark, $font-bold, 24, 30);
  }
}
